import React, { useState, useEffect } from "react";
import {
  getMyExitRequests,
  deleteMyExitRequest,
  downloadExitLetter,
  getEmployeeExitRequest,
} from "../../../store/exit/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  IconButton,
  Badge,
  Menu,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";

import SearchBox from "../../common/components/SearchBox";

import moment from "moment";
import AddExitDialog from "../components/AddExitDialog";
import StatusComentDialog from "../components/StatusComentDialog";
import EditExitDialog from "../components/EditExitDialog";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import ContentLayout from "../../common/components/ContentLayout";
import TableSkeleton from "../../common/components/TableSkeleton";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ViewExitDialog from "../components/ViewExitDialog";
import { isEmpty } from "lodash";

const MyExitRequests = (props) => {
  const {
    user,
    loading,
    myExitRequests,
    getMyExitRequests,
    deleteMyExitRequest,
    downloadExitLetter,
    getEmployeeExitRequest,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [filteredMyExitRequests, setFilteredMyExitRequests] = useState([
    ...myExitRequests,
  ]);

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [confirmRemoveRequest, setConfirmRemoveRequest] = useState(false);
  const [addRequestForm, setAddRequestForm] = useState(false);

  const [showStatusComent, setShowStatusComent] = useState(false);
  const [editRequestForm, setEditRequestForm] = useState(false);

  useEffect(() => {
    document.title = "Employee Portal - Exit | My Requests";
  });

  useEffect(
    () => {
      if (!myExitRequests.length) getMyExitRequests(setShowLoader);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const filtered = [...myExitRequests].filter(
      ({
        statusName,
        exitType,
        employeePosition,
        unitName,
        entityName,
        acronym,
      }) =>
        statusName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        exitType.toLowerCase().includes(searchTeam.toLowerCase()) ||
        employeePosition.toLowerCase().includes(searchTeam.toLowerCase()) ||
        unitName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        entityName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        acronym.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredMyExitRequests(filtered);
    paginate(filtered);
  }, [searchTeam, myExitRequests]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredMyExitRequests);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, myExitRequests, filteredMyExitRequests]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredMyExitRequests.length;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <ContentLayout>
        <div className="row">
          <div className="col-lg-4">
            <Typography
              variant="h6"
              noWrap
              component="div"
              // sx={{ display: { xs: "none", sm: "block" } }}
            >
              <span>Exit</span>
              <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                <span
                  className="material-icons  text-dark"
                  style={{ position: "relative", top: "7px" }}
                >
                  chevron_right
                </span>
              </span>
              <span style={{ color: "rgb(7, 142, 206)" }}>
                My Requests ({myExitRequests.length})
              </span>
            </Typography>
          </div>

          <div className="col-lg-8 mt-0">
            <div className="row">
              <div className="col-lg-4 mt-1"></div>
              <div className="col-lg-5 mt-1">
                <SearchBox
                  disabled={!myExitRequests.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </div>
              <div className="col-lg-3 mt-1">
                {user.currentEmployeePosition && (
                  <button
                    disabled={user.currentEmployeePosition.statusId !== 0}
                    onClick={() => {
                      setAddRequestForm(true);
                    }}
                    className="btn btn-primary  float-right"
                  >
                    <i className="fas fa-plus fa-1x  mr-1"></i> New Request
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-3"
          style={{ width: "100%", maxHeight: "100%", overflow: "auto" }}
        >
          <table className="table-striped table-hover table-sm  fixTableHead">
            <thead>
              <tr style={{ backgroundColor: "#f0f2f5" }}>
                <th scope="col" className="text-left">
                  #
                </th>

                <th scope="col" className="text-left">
                  Exit Type
                </th>

                <th scope="col" className="text-left">
                  Position
                </th>
                <th scope="col" className="text-left">
                  Institution
                </th>
                <th scope="col" className="text-right">
                  Requested On
                </th>
                <th scope="col" className="text-right">
                  Effective Date
                </th>
                <th scope="col" className="text-right">
                  Status
                </th>

                <th scope="col" className="text-right">
                  <span className="mr-3">Actions</span>
                </th>
              </tr>
            </thead>

            {showLoader ? (
              <TableSkeleton cols={8} />
            ) : (
              <tbody>
                {paginatedItems.map((myExitRequest, index) => (
                  <MyExitRequestCard
                    user={user}
                    key={index}
                    index={index}
                    myExitRequest={myExitRequest}
                    setConfirmRemoveRequest={setConfirmRemoveRequest}
                    setShowStatusComent={setShowStatusComent}
                    setEditRequestForm={setEditRequestForm}
                    downloadExitLetter={downloadExitLetter}
                    loading={loading}
                    getEmployeeExitRequest={getEmployeeExitRequest}
                  />
                ))}
              </tbody>
            )}
          </table>

          <NoRecordMessage
            isEmpty={!filteredMyExitRequests.length && !showLoader}
            title="No record found."
            message={user.currentEmployeePosition ? "Why not" : ""}
            textLink={user.currentEmployeePosition ? "request new exit?" : ""}
            onClickLink={() => {
              if (user.currentEmployeePosition) setAddRequestForm(true);
            }}
          />

          <div
            className="justify-content-center mb-3"
            style={{ overflow: "hidden" }}
          >
            {!!paginatedItems.length &&
              myExitRequests.length >= itemsPerPage && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
          </div>
        </div>
        {addRequestForm && (
          <AddExitDialog
            addRequestForm={addRequestForm}
            setAddRequestForm={setAddRequestForm}
          />
        )}

        {showStatusComent && (
          <StatusComentDialog
            showStatusComent={showStatusComent}
            selectedRequest={selectedRequest}
            setShowStatusComent={setShowStatusComent}
          />
        )}
        {editRequestForm && (
          <EditExitDialog
            selectedRequest={selectedRequest}
            editRequestForm={editRequestForm}
            setEditRequestForm={setEditRequestForm}
          />
        )}
      </ContentLayout>
    </>
  );
};

const mapStateToProps = ({ user, loading, myExitRequests }) => {
  return { user, loading, myExitRequests };
};
export default connect(mapStateToProps, {
  getMyExitRequests,
  deleteMyExitRequest,
  downloadExitLetter,
  getEmployeeExitRequest,
})(MyExitRequests);

const MyExitRequestCard = (props) => {
  const { myExitRequest, index, loading, getEmployeeExitRequest } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showViewExitModal, setShowViewExitModal] = useState(false);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showLetter, setsShowLetter] = useState(false);

  return (
    <tr key={index} className={"appeal-hovered elevated"}>
      <td className="text-left">
        <strong> {index + 1} </strong>
      </td>
      <td className="text-left text-truncate">{myExitRequest.exitType}</td>
      <td className="text-left text-truncate">
        {myExitRequest.employeePosition}({myExitRequest.unitName})
      </td>
      <td className="text-left text-truncate">
        {" "}
        {myExitRequest.entityName}({myExitRequest.acronym})
      </td>
      <td className="text-right">
        {" "}
        {!!myExitRequest.createdOn
          ? moment(myExitRequest.createdOn).format("lll")
          : ""}
      </td>

      <td className="text-right">
        {!!myExitRequest.effectiveDate
          ? moment(myExitRequest.effectiveDate).format("lll")
          : ""}
      </td>

      <td className="text-right">
        {myExitRequest.statusId === 3 && (
          <span className="badge badge-danger p-1">
            {myExitRequest.statusName}
          </span>
        )}

        {myExitRequest.statusId === 1002 && (
          <span className="badge badge-warning p-1">
            {myExitRequest.statusName}
          </span>
        )}

        {[1, 1003, 1005].includes(myExitRequest.statusId) && (
          <span className="badge badge-primary p-1">
            {myExitRequest.statusName}
          </span>
        )}
        {myExitRequest.statusId === 2 && (
          <span className="badge badge-success p-1">
            {myExitRequest.statusName}
          </span>
        )}
      </td>
      <td className="text-right">
        <>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getEmployeeExitRequest(
                    myExitRequest.employeePositionId,
                    setShowViewExitModal,
                    handleCloseMenu
                  );
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">info</span>
                View details
              </MenuItem>
              {myExitRequest.statusId === 2 && <Divider className="my-1" />}
            </span>
            {myExitRequest.statusId === 2 && (
              <span>
                <MenuItem
                  disabled={
                    loading ||
                    !myExitRequest?.docReferenceId ||
                    !myExitRequest.enableToGenerateLetter
                  }
                  onClick={() => {
                    setsShowLetter(true);
                  }}
                  className="text-primary font-weight-light"
                >
                  <span className="material-icons mr-1">open_in_new</span>
                  View approved letter
                </MenuItem>
              </span>
            )}
          </Menu>
        </>
      </td>
      {showLetter &&
        myExitRequest.statusId === 2 &&
        !!myExitRequest?.docReferenceId && (
          <PreviewPdfUrl
            showDocument={showLetter}
            setShowDocument={setsShowLetter}
            document={{
              name: `View ${myExitRequest.exitType} Letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                myExitRequest?.docReferenceId,
            }}
          />
        )}
      {!!showViewExitModal && (
        <ViewExitDialog
          showViewExitModal={showViewExitModal}
          setShowViewExitModal={setShowViewExitModal}
        />
      )}
    </tr>
  );
};
