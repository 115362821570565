const types = {
  SET_MY_EXIT_REQUESTS: "SET_MY_EXIT_REQUESTS",
  SET_EXIT_TYPES: "SET_EXIT_TYPES",
  DELETE_MY_REQUEST: "DELETE_MY_REQUEST",
  ADD_MY_REQUEST: "ADD_MY_REQUEST",
  UPDATE_MY_REQUEST: "UPDATE_MY_REQUEST",
  SET_REHABILITATIONS: "SET_REHABILITATIONS",
  ADD_REHABILITATION: "ADD_REHABILITATION",
  DELETE_REHABILITATION: "DELETE_REHABILITATION",
  UPDATE_REHABILITATION: "UPDATE_REHABILITATION",
  SET_EMPLOYEE_EXIT_REQUEST: "SET_EMPLOYEE_EXIT_REQUEST",

  ADD_EXIT_REQUEST_ATTACHMENT:"ADD_EXIT_REQUEST_ATTACHMENT",
  SET_EXIT_REQUEST_ATTACHMENTS:"SET_EXIT_REQUEST_ATTACHMENTS",
  UPDATE_EXIT_REQUEST_ATTACHMENTS:"UPDATE_EXIT_REQUEST_ATTACHMENTS"
};

export default types;
