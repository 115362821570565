import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSalary } from "../../../store/payroll/actions";
import { Skeleton, Tooltip as MUTooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getMyActivities } from "../../../store/rbm/actions";
import { getAnalytics, refreshBRDBalance } from "../../../store/common/actions";
import { currencyFormat, textCapitalized } from "../../common/components/Utils";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import InfoIcon from "@mui/icons-material/Info";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  BarElement,
  Title,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

import { Line, Doughnut, Pie, Bar } from "react-chartjs-2";
import axios from "axios";
import ProfileStatus from "../../profile/components/ProfileStatus";
import { isEmpty } from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

const Dashboard = (props) => {
  useEffect(() => {
    document.title = "Employee SelfService - Home";
  });

  const { user, analytics, getAnalytics, loading, refreshBRDBalance } = props;
  const [period, setPeriod] = useState("Good Morning");
  const [currentEmployment, setcurrentEmployment] = useState(null);

  const [totalProgress, setTotalProgress] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  // const [myActivitiesSummary, setMyActivitiesSummary] = useState({
  //   allActivities: 0,
  //   dueActivities: 0,
  //   reportedActivities: 0,
  //   scoredActivities: 0,
  //   targetAchieved: 0,
  // });

  // useEffect(
  //   () => {
  //     if (!myActivities.length) getMyActivities(history);
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  // useEffect(() => {
  //   let weight = 0,
  //     score = 0,
  //     allActivities = 0,
  //     dueActivities = 0,
  //     reportedActivities = 0,
  //     scoredActivities = 0,
  //     targetAchieved = 0,
  //     totalTarget = 0,
  //     allDueActivities = [],
  //     rejectedActivities = [];
  //   myActivities.forEach((myActivity) => {
  //     myActivity.activities.forEach((activity) => {
  //       targetAchieved = targetAchieved + activity.achievedTarget;
  //       totalTarget = totalTarget + activity.targetShare;

  //       allActivities = ++allActivities;
  //       if (activity.status === "Rejected") rejectedActivities.push(activity);
  //       if (activity.isDue) allDueActivities.push(activity);

  //       if (activity.isDue) ++dueActivities;
  //       if (
  //         activity.status === "Reported" ||
  //         activity.status === "Scored" ||
  //         activity.status === "Rejected"
  //       )
  //         reportedActivities++;
  //       if (activity.status === "Scored" || activity.status === "Rejected")
  //         ++scoredActivities;

  //       score = score + (activity.score * activity.weight) / 100;
  //       weight = weight + activity.weight;
  //     });
  //   });

  //   if ((score * 100) / (weight || 1) >= 90 && user.employeeId !== 94953)
  //     setTotalProgress((score * 100) / (weight || 1) - 8.0);
  //   else setTotalProgress((score * 100) / (weight || 1));

  //   setMyActivitiesSummary({
  //     allActivities,
  //     dueActivities,
  //     reportedActivities,
  //     scoredActivities,
  //     targetAchieved: (targetAchieved * 100) / (totalTarget || 1),
  //   });
  // }, [myActivities]);

  useEffect(() => {
    const currentEmployment =
      (!isEmpty(user) && user.currentEmployeePosition) || null;
    setcurrentEmployment(currentEmployment);

    let now = new Date();

    let isMorning = now.getHours() > 5 && now.getHours() <= 12;
    let isAfternoon = now.getHours() > 12 && now.getHours() <= 18;
    let isEvening = now.getHours() > 18 && now.getHours() <= 22;
    let isNight = now.getHours() > 22 || now.getHours() <= 5;

    if (isMorning) setPeriod("Good Morning");
    if (isAfternoon) setPeriod("Good Afternoon");
    if (isEvening) setPeriod("Good Evening");
    if (isNight) setPeriod("Good Night");
  }, [user]);
  useEffect(() => {
    if (!analytics.payrolls.length) getAnalytics(setShowLoader);
  }, []);

  return (
    <>
      <div
      // style={{
      //   overflow: "hidden",
      // }}
      >
        <div className="container-fluid mt-4">
          <div className="row">
            <div
              className={` col-12 col-md-5 col-lg-4 col-xl-3 mb-3  order-first  order-md-last`}
            >
              {/* <div className="card rounded">
                    <div className="card-header">
                      <div className="card-title flex-column">
                        <span className="text-gray-800 ">
                          <NotificationsActiveIcon />
                          Notifications
                        </span>
                      </div>
                    </div>
                  </div> */}

              <ProfileStatus />
            </div>

            <div
              className={`col-12 col-md-7 col-lg-8 col-xl-9 order-last  order-md-first`}
            >
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6">
                      {currentEmployment && (
                        <h6>
                          Status:
                          {!currentEmployment.hasPendingExitRequest && (
                            <strong
                              className={`ml-3 badge badge-${
                                currentEmployment.statusId === 0
                                  ? "success"
                                  : "warning"
                              }`}
                              style={{
                                fontSize: ".75rem",
                                fontweight: "bold",
                              }}
                            >
                              {currentEmployment.statusName}
                            </strong>
                          )}
                          {currentEmployment.hasPendingExitRequest && (
                            <span className="badge badge-danger">
                              Pending Exit Request
                            </span>
                          )}
                          {currentEmployment.isActing &&
                          !currentEmployment.hasPendingExitRequest ? (
                            <MUTooltip
                              title={
                                <>
                                  Ag. {currentEmployment.actingPosition}(
                                  {currentEmployment.actingUnity})
                                </>
                              }
                            >
                              <strong
                                className="ml-3 badge badge-primary"
                                style={{
                                  fontSize: ".75rem",
                                  fontweight: "bold",
                                }}
                              >
                                (Acting){" "}
                                <InfoIcon
                                  style={{
                                    fontSize: ".80rem",
                                    fontweight: "bold",
                                  }}
                                />
                              </strong>
                            </MUTooltip>
                          ) : (
                            ""
                          )}
                        </h6>
                      )}
                    </div>
                    <div className="col-6">
                      <h6
                        style={{
                          fontsize: "1rem",
                          fontWeight: 600,
                          letterSpacing: "2px",
                          color: "#088dce",
                          lineHeight: "1.2rem",
                          marginBottom: "8px",
                          textAlign: "right",
                          textTransform: "uppercase",
                        }}
                      >
                        Employee Id:{user && user.id}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-2">
                  <div
                    className="card rounded  bg-light-success  mb-5"
                    style={{
                      border: "1px solid #078ece",
                    }}
                  >
                    <div className="card-body d-flex justify-content-center flex-wrap  pe-0">
                      <div className="flex-grow-1 mt-0 me-9 me-md-0">
                        <div className="position-relative text-gray-800 fs-5 z-index-2 fw-bold">
                          <span>
                            {period},
                            <strong className="ml-2 employee-name text-capitalize">
                              {user && textCapitalized(user.firstName)}
                            </strong>
                          </span>
                        </div>

                        <span className="text-gray-600 fw-semibold fs-6 mb-2 d-block">
                          {currentEmployment && (
                            <>
                              <span className="text-capitalize">
                                {textCapitalized(
                                  currentEmployment.positionName
                                )}
                                ({textCapitalized(currentEmployment.unitName)})
                              </span>
                              <span className="ml-1">in</span>
                            </>
                          )}
                          <span className="ml-1">
                            <span className="text-gray-800 text-capitalize">
                              {currentEmployment &&
                                textCapitalized(currentEmployment.entityName)}
                            </span>
                            (
                            {currentEmployment &&
                              textCapitalized(currentEmployment.acronym)}
                            )
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mb-3">
                  <div
                    className="card rounded"
                    style={{
                      border: "1px solid #078ece",
                    }}
                  >
                    <div className="card-header">
                      <div className="card-title flex-column">
                        <span className="text-gray-800 text-uppercase">
                          <AccountBalanceWalletIcon className="mr-1" />
                          PAYROLL
                        </span>
                      </div>
                    </div>
                    <div className="card-body px-2 py-2">
                      {!showLoader && !analytics?.payrolls?.length && (
                        <div className="col-lg-12 mb-3">
                          <div
                            className="card rounded"
                            style={{
                              border: "1px solid #078ece",
                            }}
                          >
                            <div className="card-header">
                              <div className="card-title flex-column">
                                <span className="text-gray-800 text-uppercase">
                                  NO PAYROLL INPROGRESS
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {!showLoader && !!analytics?.payrolls?.length && (
                        <>
                          {analytics?.payrolls.map((payroll, index) => (
                            <div className="col-lg-12 mb-3" key={index}>
                              <div
                                className="card rounded"
                                style={{
                                  border: "1px solid #078ece",
                                  cursor: "pointer",
                                }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                  }}
                                  to="/payroll/salary"
                                >
                                  <div className="card-header">
                                    <div className="card-title flex-column">
                                      <span className="text-gray-800 text-uppercase">
                                        {payroll?.payrollTypeName} OF{" "}
                                        <strong className="text-primary text-uppercase">
                                          {" "}
                                          {payroll &&
                                            payroll?.dateHumanReadable}{" "}
                                        </strong>{" "}
                                        <span className="mr-2 badge badge-secondary">
                                          {payroll?.payrollGroupName}{" "}
                                        </span>
                                      </span>
                                      <div className="float-right ">
                                        {/* Status: */}
                                        <span
                                          className={` mr-2 badge badge-${
                                            payroll?.statusId ===
                                              "RECEIVED_BY_BANK" ||
                                            payroll?.statusId ===
                                              "SEND_TO_BANK" ||
                                            payroll?.statusId ===
                                              "SIGNED_AS_PAID"
                                              ? "success"
                                              : "info"
                                          }`}
                                          style={{ width: "96px" }}
                                        >
                                          {payroll?.statusId ===
                                            "SEND_TO_BANK" ||
                                          payroll?.statusId === "SIGNED_AS_PAID"
                                            ? "Paid"
                                            : "Inprogress..."}
                                        </span>
                                        <MUTooltip
                                          title={
                                            <>
                                              View Payroll{" "}
                                              {payroll?.payrollTypeName} Summary
                                            </>
                                          }
                                        >
                                          <ArrowCircleRightIcon className="text-primary" />
                                        </MUTooltip>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </>
                      )}

                      {showLoader && (
                        <div className="col-lg-12 mb-3">
                          <div
                            className="card rounded"
                            style={{
                              border: "1px solid #078ece",
                            }}
                          >
                            <div className="card-header">
                              <div className="card-title flex-column">
                                <span className="text-gray-800">
                                  <AccountBalanceWalletIcon className="mr-1" />

                                  <Skeleton />
                                </span>
                                <span className="float-right">
                                  <Skeleton />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, analytics }) => {
  return { user, loading, analytics };
};
export default connect(mapStateToProps, { getAnalytics, refreshBRDBalance })(
  Dashboard
);
