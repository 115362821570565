import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getExitTypes, saveMyExitRequest } from "../../../store/exit/actions";
import { showError } from "../../../app/toastify";
import WidgetUploadComponent from "../../common/components/WidgetUploadComponent";
import ErrorMessage from "../../common/components/ErrorMessage";
import moment from "moment";

const AddExitDialog = (props) => {
  const {
    user,
    loading,
    addRequestForm,
    setAddRequestForm,
    exitTypes,
    getExitTypes,
    saveMyExitRequest,
  } = props;

  const onClose = () => {
    setAddRequestForm(false);
  };
  const [yesIAgree, setYesIAgree] = useState(false);
  const [yesIAgreeChecked, setYesIAgreeChecked] = useState(false);

  const [exitType, setExitType] = useState(null);

  const [formData, setFormData] = useState({
    employeePositionId: user?.currentEmployeePosition.employeePositionId,
    exitTypeId: "",
    message: "",
    effectiveOn: moment(user.currentDate).format("YYYY-MM-DD"),
    attachedFiles: [],
    blacklistPeriod: 0,
  });

  const [errors, setErrors] = useState({
    fileHasError: false,
    exitTypeHasError: false,
    messageHasError: false,
    effectiveOnHasError: false,
  });

  useEffect(() => {
    getExitTypes();
  }, []);

  const formValidator = () => {
    const error = {
      fileHasError: false,
      exitTypeHasError: false,
      messageHasError: false,
      effectiveOnHasError: false,
    };

    if (!exitType) {
      error.exitTypeHasError = true;
      error.hasError = true;
    }
    if (!formData.message) {
      error.messageHasError = true;
      error.hasError = true;
    }

    if (!formData.effectiveOn) {
      error.effectiveOnHasError = true;
      error.hasError = true;
    }
    if (!formData.attachedFiles.length) {
      error.fileHasError = true;
      error.hasError = true;
    }
    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!errors.fileHasError) {
      if (!formValidator()) {
        formData.effectiveOn = moment(formData.effectiveOn).format(
          "YYYY-MM-DD"
        );
        const payload = new FormData();
        const keys = Object.keys(formData);

        for (const key of keys) {
          if (formData.hasOwnProperty(key)) {
            if (
              key === "attachedFiles" &&
              formData[key] &&
              !!formData[key].length
            )
              formData[key].forEach((row, i) => {
                payload.append(key, row.file);
              });
            else payload.append(`${key}`, `${formData[key]}`);
          }
        }

        saveMyExitRequest(payload, setFormData, setExitType, setAddRequestForm);
      }
    }
  };
  const onUploadSuccess = (files) => {
    setErrors({ ...errors, fileHasError: false });
    setFormData({ ...formData, attachedFiles: files });
  };
  const onUploadErrors = (errors) => {
    if (errors) {
      setErrors({ ...errors, fileHasError: errors.fileHasError });
      showError(errors.message);
    }
  };
  const getBlacklistPeriod = () =>
    +(exitType?.blacklistPeriod || 0) > 1
      ? (exitType?.blacklistPeriod || 0).toString() + " Years"
      : (exitType?.blacklistPeriod || 0).toString() + " Year";
 

  const calculateFutureDate = (effectiveInDays) => {
    const futureDate = moment(user.currentDate).add(effectiveInDays, "days");
    return futureDate.format("YYYY-MM-DD"); // Returns the date in 'YYYY-MM-DD' format
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={addRequestForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Request New Exit
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: "450px" }}>
          <div className="m-2">
            <Autocomplete
              size="small"
              id="exitType"
              defaultValue={null}
              value={exitType}
              options={exitTypes}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, exitType) => {
                setExitType(exitType || null);
                const exitTypeId = exitType ? exitType.id : "";
                setFormData({
                  ...formData,
                  exitTypeId,
                  effectiveOn: calculateFutureDate(exitType.effectiveInDays),
                });
                setYesIAgree(true);
                setYesIAgreeChecked(!!exitType.description ? false : true);
                const exitTypeHasError = false;
                setErrors({ ...errors, exitTypeHasError });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, exitType) => (
                <Box component="li" {...props}>
                  {exitType.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Exit Type"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "exitType", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <ErrorMessage
              hasError={errors.exitTypeHasError}
              message="Exit Type is required"
            />
          </div>
          {!!yesIAgree && exitType && exitType.description && (
            <div className="row">
              <div className="col-12">
                <div className="card p-3">
                  {/* </div> */}
                  <div className="mt-0">
                    <span className="font-weight-bold">
                      Declaration of {exitType.name}
                    </span>
                    <div className="mt-3">
                      <span>
                        I{" "}
                        <span className="text-primary">
                          {user.firstName + " " + user.lastName}
                        </span>
                        ,
                      </span>

                      <p
                        className="mt-2"
                        dangerouslySetInnerHTML={{
                          __html: exitType.description,
                        }}
                      ></p>
                    </div>
                  </div>
                  <div
                    className={
                      yesIAgreeChecked
                        ? "border p-2 border-success"
                        : "border p-2 border-primary"
                    }
                  >
                    <div
                      className="d-flex flex-row align-items-center cursor-pointer max-w-content mr-1 "
                      style={{ fontSize: "16px" }}
                      onClick={() => {
                        setYesIAgreeChecked(!yesIAgreeChecked);
                        setYesIAgree(!yesIAgree);
                      }}
                    >
                      <span className="font-weight-bold text-primary">
                        <span
                          className={
                            yesIAgreeChecked
                              ? "text-success mr-2"
                              : "text-primary mr-2"
                          }
                        >
                          Yes, I agree
                        </span>
                      </span>
                      {yesIAgreeChecked ? (
                        <CheckBoxRoundedIcon className="text-success" />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {yesIAgreeChecked && (
            <>
              {!!exitType && parseInt(exitType.blacklistPeriod) > 0 && (
               <div className="m-2">
                  <TextField
                    style={{ backgroundColor: "#eee" }}
                    fullWidth
                    size="small"
                    name="blacklistPeriod"
                    label="Blacklist Period"
                    value={getBlacklistPeriod()}
                    disabled
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        blacklistPeriod: e.target.value,
                      });
                    }}
                  />

                  <span className="badge badge-primary my-1">
                    To be Blacklisted in {getBlacklistPeriod()}
                  </span>
                </div>
              )}{" "}
              <div className="m-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Effective Date"
                    inputFormat="MM/dd/yyyy"
                    value={formData.effectiveOn}
                    onChange={(date) => {
                      const effectiveOn = date;
                      setFormData({ ...formData, effectiveOn });
                      const effectiveOnHasError = false;
                      setErrors({ ...errors, effectiveOnHasError });
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth size="small" {...params} />
                    )}
                  />
                </LocalizationProvider>

                <ErrorMessage
                  hasError={errors.effectiveOnHasError}
                  message="Effective Date is required"
                />
              </div>
              <div className="m-2">
                <TextField
                  required
                  id="outlined-required"
                  label="Comments..."
                  fullWidth
                  onChange={(e) => {
                    const message = e.target.value;
                    setFormData({ ...formData, message });
                    const messageHasError = false;
                    setErrors({ ...errors, messageHasError });
                  }}
                />
                <ErrorMessage
                  hasError={errors.messageHasError}
                  message="Message is required"
                />
              </div>
              <div className="m-2">
                <WidgetUploadComponent
                  onSuccess={onUploadSuccess}
                  onErrors={onUploadErrors}
                  multiple={true}
                  acceptedFileSize="1074176"
                  acceptedFileTypes="pdf"
                  label="Upload supporting document *(pdf)"
                />
                <ErrorMessage
                  hasError={
                    errors.fileHasError && !formData.attachedFiles.length
                  }
                  message="Supporting document is required"
                />
              </div>
            </>
          )}
        </DialogContent>
        {yesIAgreeChecked && (
          <DialogActions className="d-flex justify-content-center py-4">
            <button
              onClick={onSave}
              type="button"
              className="btn btn-primary  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Submit"}
            </button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user, exitTypes }) => {
  return {
    loading,
    user,
    exitTypes,
  };
};
export default connect(mapStateToProps, {
  getExitTypes,
  saveMyExitRequest,
})(AddExitDialog);
