import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import moment from "moment";
import { requestStatusColor } from "../../common/components/Utils";

const ViewCaseLogs = (props) => {
  const { logs } = props;

  return (
    <>
      <div>
        <Box sx={{ width: "100%" }}>
          <React.Fragment>
            <div
              style={{
                width: "100%",
              }}
            >
              <p className="contact_p">
                <>
                  {!!logs.length && (
                    <>
                      <table style={{ marginTop: 8, marginBottom: 8 }}>
                        <tbody>
                          {logs.map((log, index) => (
                            <>
                              <div key={index} className="row">
                                <div className="col-12 d-flex justify-content-left px-2 py-2">
                                  <div
                                    className={`card border-${requestStatusColor(
                                      [
                                        {
                                          className: "success",
                                          statusIds: [6,13],
                                        },
                                        {
                                          className: "danger",
                                          statusIds: [10],
                                        },
                        
                                        {
                                          className: "primary",
                                          statusIds: [3, 5,12],
                                        },
                                        {
                                          className: "warning",
                                          statusIds: [2, 7,4],
                                        },
                                        {
                                          className: "secondary",
                                          statusIds: [4, 8],
                                        },
                                        {
                                          className: "secondary",
                                          statusIds: [1, 9, 11],
                                        },
                                      ],
                                      log.statusId
                                    )}`}
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: 800,
                                      }}
                                      className={`card-header text-left border-${requestStatusColor(
                                        [
                                          {
                                            className: "success",
                                            statusIds: [6,13],
                                          },
                                          {
                                            className: "danger",
                                            statusIds: [10],
                                          },
                          
                                          {
                                            className: "primary",
                                            statusIds: [3, 5,12],
                                          },
                                          {
                                            className: "warning",
                                            statusIds: [2, 7,4],
                                          },
                                          {
                                            className: "secondary",
                                            statusIds: [4, 8],
                                          },
                                          {
                                            className: "secondary",
                                            statusIds: [1, 9, 11],
                                          },
                                        ],
                                      log.statusId
                                    )}
                                     text-${requestStatusColor(
                                      [
                                        {
                                          className: "success",
                                          statusIds: [6,13],
                                        },
                                        {
                                          className: "danger",
                                          statusIds: [10],
                                        },
                        
                                        {
                                          className: "primary",
                                          statusIds: [3, 5,12],
                                        },
                                        {
                                          className: "warning",
                                          statusIds: [2, 7,4],
                                        },
                                        {
                                          className: "secondary",
                                          statusIds: [4, 8],
                                        },
                                        {
                                          className: "secondary",
                                          statusIds: [1, 9, 11],
                                        },
                                      ],
                                        log.statusId
                                      )}`}
                                    >
                                      <TaskAltIcon />
                                      {log.statusName.toUpperCase()}
                                    </div>
                                    <div className={`card-body`}>
                                      <Card sx={{ maxWidth: "100%" }}>
                                        <CardHeader
                                          avatar={
                                            <Avatar
                                              sx={{
                                                bgcolor: "#ccc",
                                              }}
                                            >
                                              {log.firstName
                                                .charAt(0)
                                                .toUpperCase()}
                                            </Avatar>
                                          }
                                          title={`${log.firstName}`}
                                          subheader={moment(
                                            log?.statusOn
                                          ).format("lll")}
                                          action={
                                            <span className="text-primary">
                                              {/* xx */}
                                              {/* {approval.reviewerPositionName} */}
                                            </span>
                                          }
                                        />

                                        <CardContent>
                                          <Typography
                                            style={{
                                              marginTop: "-15px",
                                            }}
                                            variant="body2"
                                            color="text.secondary"
                                          >
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: log.comments,
                                              }}
                                            ></span>
                                          </Typography>
                                        </CardContent>
                                      </Card>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </>
              </p>
            </div>
          </React.Fragment>
        </Box>
      </div>
    </>
  );
};

export default ViewCaseLogs;
