import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import {
  login,
  selectModule,
  resetPassword,
  logout,
  selectEntitySector,
  requestOTP,
} from "../store/common/actions";

import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  OutlinedInput,
  TextField,
} from "@mui/material";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import SafetyCheckRoundedIcon from "@mui/icons-material/SafetyCheckRounded";
import { isEmpty } from "lodash";
import axios from "axios";
import CustomCountdown from "./common/components/CustomCountdown";
import { blue, red } from "@mui/material/colors";
const Home = (props) => {
  const { user, loading, login, resetPassword, requestOTP, env } = props;

  const history = useHistory();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [onWait, setOnWait] = useState(false);
  const [wasReset, setWasReset] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [resending, setResending] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const [otpMethod, setOtpMethod] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);
  useEffect(
    () => {
      if (!isEmpty(user)) history.push("/home");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    showPassword: false,
    ipAddress: "",
  });
  const handleCleanForm = () => {
    setCredentials({
      email: "",
      password: "",
      showPassword: false,
    });
    setVerificationCode("");
    setRemainingSeconds(120);
    setFormStep(1);
    setForgotPassword(false);
    setResending(false);
    setOtpMethod(null);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      handleCleanForm();
    }
  }, [user]);

  useEffect(() => {
    if (verificationCode.length === 6) onLogin();
  }, [verificationCode]);
  const canVerify = () => {
    return (
      remainingSeconds > 0 &&
      verificationCode.length >= 4 &&
      !loading &&
      !resending
    );
  };

  const handleRequestOTP = (notificationMethod) => {
    setOtpValues(["", "", "", "", "", ""]);
    setCredentials((prev) => ({
      ...prev,
      isNew: false,
      errMsg: null,
    }));
    requestOTP(
      {
        email: credentials.email,
        password: credentials.password,
        notificationMethod: notificationMethod || otpMethod || null,
      },
      (status, res, data) => {
        setResending(status);
        if (data && res) {
          if (!!data.isCredentialValid) {
            setFormStep(2);
          } else {
            if (notificationMethod === "mail" && !!data.seconds) {
              setRemainingSeconds(data.seconds);
            }
            if (notificationMethod === "authenticator" && !!data.isNew) {
              setCredentials({
                ...credentials,
                isNew: data.isNew,
                errMsg: data.message,
              });
            }
          }
        }
      }
    );
  };
  const onLogin = async (e) => {
    e && e.preventDefault();
    if (env !== "prod" || window.location.hostname === "localhost") {
      login(
        {
          email: credentials.email,
          password: credentials.password,
          wasLoggedIn: false,
        },
        history
      );
    } else {
      if (formStep === 1) {
        handleRequestOTP();
      } else if (formStep === 2) {
        login(
          {
            email: credentials.email,
            notificationMethod: otpMethod,
            otpCode: env === "prod" ? `${verificationCode}` : undefined,
            password: credentials.password,
            wasLoggedIn: false, //env !== "prod" ? false : true,
            ipAddress: credentials.ipAddress,
          },
          history
        );
      }
    }
  };

  useEffect(() => {
    getClientIp();
  }, []);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);
      setCredentials({
        ...credentials,
        ipAddress: data,
      });
    } catch (error) {}
  };

  const handleReset = (e) => {
    e.preventDefault();
    if (credentials.email.length)
      resetPassword(credentials.email, (loading, status) => {
        setOnWait(loading);
        setWasReset(status);
      });
  };

  const handleGetHashedEmail = (email) => {
    const emailArray = email.split("@");
    const firstPart = emailArray[0];
    const atPart = emailArray[1];
    if (firstPart.length <= 3) return `${firstPart[0]}******@${atPart}`;
    else if (firstPart.length <= 5)
      return `${firstPart.substring(0, 2)}******@${atPart}`;
    else return `${firstPart.substring(0, 3)}******@${atPart}`;
  };

  const changeOTPMethod = (value) => {
    setOtpMethod(value);
    handleRequestOTP(value);
  };
  const handleInputChange = (index, value) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    const otpCode = newOtpValues.join("");
    setVerificationCode(otpCode);
  };
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    const cleanedData = pasteData.replace(/\D/g, "").slice(0, 6);

    const newOtpValues = [...otpValues];
    cleanedData.split("").forEach((char, index) => {
      if (index < otpValues.length) {
        newOtpValues[index] = char;
      }
    });

    setOtpValues(newOtpValues);
    const otpCode = newOtpValues.join("");
    setVerificationCode(otpCode);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = "";

      if (index > 0) {
        setOtpValues(newOtpValues);
        inputRefs.current[index - 1].focus();
      } else {
        setOtpValues(newOtpValues);
      }

      const otpCode = otpValues.join("");
      setVerificationCode(otpCode);
    }
  };
  // console.log(verificationCode.length);
  return (
    <React.Fragment>
      {isEmpty(user) && (
        <div className="container-fluid px-0">
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
          </div>

          <div
            className="row justify-content-center pt-3 mx-0 px-0 text-center"
            style={{
              marginTop: "-142px",
            }}
          >
            {!forgotPassword && (
              <div
                className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.125)",
                }}
              >
                {formStep === 1 ? (
                  <form onSubmit={onLogin}>
                    <h1 className="h3 mb-3 font-weight-normal">Login</h1>

                    <FormControl fullWidth variant="outlined" className="mb-3">
                      <InputLabel htmlFor="email">Enter your email</InputLabel>
                      <OutlinedInput
                        // size="small"
                        style={{
                          // height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        id="email"
                        placeholder="Email"
                        type="text"
                        name="email"
                        value={credentials.email}
                        onChange={(e) => {
                          const email = e.target.value;
                          setCredentials({ ...credentials, email });
                        }}
                        label="Enter your email"
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        // size="small"
                        style={{
                          // height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        id="password"
                        placeholder="Password"
                        name="password"
                        type={credentials.showPassword ? "text" : "password"}
                        value={credentials.password}
                        onChange={(e) => {
                          const password = e.target.value;
                          setCredentials({ ...credentials, password });
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setCredentials({
                                  ...credentials,
                                  showPassword: !credentials.showPassword,
                                })
                              }
                              edge="end"
                            >
                              {credentials.showPassword ? (
                                <span className="material-icons">
                                  visibility_off
                                </span>
                              ) : (
                                <span className="material-icons">
                                  visibility
                                </span>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>

                    <div className="text-right">
                      <Button
                        onClick={() => setForgotPassword(true)}
                        className="mb-3 pr-0"
                      >
                        Forgot password?
                      </Button>
                    </div>

                    {!loading && (
                      <Button
                        type="submit"
                        disabled={loading}
                        variant="contained"
                        disableElevation
                        className="btn btn-block"
                        size="large"
                      >
                        Login
                      </Button>
                    )}

                    {loading && (
                      <Button
                        variant="contained"
                        disableElevation
                        className="btn btn-block"
                        size="large"
                      >
                        Connecting...
                      </Button>
                    )}

                    <p className="mt-4 mb-3 text-muted">
                      If you don't remember your email. <br />
                      Contact your HR for help.
                    </p>
                  </form>
                ) : (
                  <div>
                    <h3 className="font-weight-bold mt-3">
                      Where to send notifications
                    </h3>
                    {!otpMethod && (
                      <Alert variant="filled" severity="info" className="mt-3">
                        Please choose 2 Factor Authentication option.
                      </Alert>
                    )}
                    <div className="card bg-white mb-2 mt-4 border-0">
                      <div className="card-body px-5 py-0 text-center">
                        <List>
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => changeOTPMethod("mail")}
                              selected={otpMethod === "mail"}
                              disabled={resending || otpMethod === "mail"}
                            >
                              <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[500] }}>
                                  <EmailRoundedIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary="Email" />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => changeOTPMethod("authenticator")}
                              selected={otpMethod === "authenticator"}
                              disabled={
                                resending || otpMethod === "authenticator"
                              }
                            >
                              <Avatar
                                sx={{ bgcolor: red[500] }}
                                className="mr-3"
                              >
                                <SafetyCheckRoundedIcon />
                              </Avatar>
                              <ListItemText primary="Authenticator App" />
                            </ListItemButton>
                          </ListItem>
                        </List>

                        {!!otpMethod && (
                          <form onSubmit={onLogin}>
                            {otpMethod === "mail" && (
                              <p style={{ color: "#696969" }} className="mt-4">
                                Please enter a One-Time Password (OTP) you
                                received on your email <br />
                                <strong className="text-primary">
                                  {handleGetHashedEmail(credentials.email)}
                                </strong>
                              </p>
                            )}

                            {otpMethod === "authenticator" &&
                              !!credentials.isNew &&
                              !!credentials.errMsg && (
                                <>
                                  <p
                                    style={{
                                      color: "#950505",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                    className="mt-1"
                                  >
                                    {credentials.errMsg}
                                  </p>
                                  <Alert
                                    variant="filled"
                                    severity="info"
                                    className="mt-3"
                                  >
                                    To enable the Authenticator App option, you
                                    must first log in and navigate to your
                                    account settings to set it up.
                                  </Alert>
                                </>
                              )}
                            {otpMethod === "authenticator" && (
                              <p style={{ color: "#696969" }} className="mt-4">
                                Please enter a One-Time Password (OTP) you
                                received on your Authenticator App
                                <span className="ml-1">
                                  ( ex: Google or Microsoft Authenticator )
                                </span>
                              </p>
                            )}

                            <div className="mb-3 w-100">
                              <article
                                id="otp"
                                className="d-flex flex-row justify-center text-center mx-5"
                                onPaste={handlePaste}
                              >
                                {otpValues.map((value, index) => (
                                  <input
                                    disabled={
                                      (otpMethod === "authenticator" &&
                                        !!credentials.isNew) ||
                                      (remainingSeconds <= 0 &&
                                        otpMethod === "mail") ||
                                      loading
                                    }
                                    key={index}
                                    ref={(ref) =>
                                      (inputRefs.current[index] = ref)
                                    }
                                    className="m-2 border h-8 w-8 text-center form-control rounded focus:border-none outline-none focus:outline-primary max-md:w-8 max-md:h-8"
                                    type="text"
                                    placeholder="-"
                                    value={value}
                                    onChange={(e) =>
                                      handleInputChange(index, e.target.value)
                                    }
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    maxLength="1"
                                  />
                                ))}
                              </article>
                            </div>
                            <div className="d-flex align-items-center justify-content-center mx-auto mb-2">
                              <Button
                                className="mx-2"
                                variant="contained"
                                type="submit"
                                disabled={
                                  (otpMethod === "authenticator" &&
                                    !!credentials.isNew) ||
                                  (canVerify() ? false : true)
                                }
                              >
                                Verify
                              </Button>
                              {otpMethod === "mail" && (
                                <Button
                                  className="mx-2"
                                  variant="outlined"
                                  color="primary"
                                  type="button"
                                  disabled={remainingSeconds > 0}
                                  onClick={() => {
                                    setResending(true);
                                    handleRequestOTP();
                                  }}
                                >
                                  Resend
                                </Button>
                              )}
                            </div>

                            {remainingSeconds > 0 &&
                              !resending &&
                              otpMethod === "mail" && (
                                <div className="mb-3">
                                  <CustomCountdown
                                    remainingSeconds={remainingSeconds}
                                    setRemainingSeconds={setRemainingSeconds}
                                  />
                                </div>
                              )}
                          </form>
                        )}
                      </div>
                    </div>
                    <Button
                      variant="text"
                      type="button"
                      className="mt-4"
                      onClick={handleCleanForm}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            )}

            {forgotPassword && (
              <div className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6">
                <h1 className="h3 mb-3 font-weight-normal">Reset password</h1>
                {wasReset && (
                  <Alert severity="success" className="mb-3">
                    <AlertTitle>
                      Please, check your email to continue.
                    </AlertTitle>
                    The link to reset your password was sent to your email.
                  </Alert>
                )}

                {!wasReset && (
                  <form onSubmit={handleReset}>
                    <TextField
                      // size="small"
                      style={{
                        height: "35px!important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px!important",
                      }}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      label="Enter your email"
                      placeholder="Email"
                      className="mb-3"
                      value={credentials.email}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          email: e.target.value,
                        })
                      }
                      disabled={loading || onWait}
                    />

                    {!onWait && (
                      <Button
                        type="submit"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || credentials.email.length === 0}
                      >
                        Reset password
                      </Button>
                    )}

                    {onWait && (
                      <Button
                        type="button"
                        variant="contained"
                        disableElevation
                        className="btn btn-block text-light"
                        size="large"
                        disabled={loading || onWait}
                      >
                        Connecting...
                      </Button>
                    )}
                  </form>
                )}

                <Button
                  onClick={() => {
                    setForgotPassword(false);
                    setOnWait(false);
                    setWasReset(false);
                  }}
                  className="pr-0 mt-3"
                  disabled={loading || onWait}
                >
                  <span className="material-icons">
                    keyboard_double_arrow_left
                  </span>{" "}
                  Back to Login
                </Button>

                {!wasReset && (
                  <p className="mt-4 text-muted">
                    If you don't remember your email. <br />
                    Contact your HR for help.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {/* {!isEmpty(user) && (
        <>
          <WelcomePageMenuBar />
          <WelcomePage />
        </>
      )} */}
    </React.Fragment>
  );
};

const mapStateToProps = ({
  user,
  isFetchingUser,
  loading,
  ippisModules,
  env,
}) => {
  return { user, isFetchingUser, loading, ippisModules, env };
};
export default connect(mapStateToProps, {
  login,
  resetPassword,
  logout,
  selectModule,
  selectEntitySector,
  requestOTP,
})(Home);
