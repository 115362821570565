import { defaultState } from "./state";
import exit from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...exit };

const myExitRequests = (
  myExitRequestsState = defaultState.myExitRequests,
  action
) => {
  switch (action.type) {
    case types.SET_MY_EXIT_REQUESTS:
      return action.data;

    case types.ADD_MY_REQUEST:
      const tmpMyExitRequestsState = [...myExitRequestsState];
      tmpMyExitRequestsState.unshift(action.data);
      return tmpMyExitRequestsState;

    case types.UPDATE_MY_REQUEST: {
      const tmpMyExitRequestsState = [...myExitRequestsState];
      const index = tmpMyExitRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpMyExitRequestsState[index] = action.data;

      return tmpMyExitRequestsState;
    }

    case types.DELETE_MY_REQUEST:
      const tmpRemoveMyRequestsState = [...myExitRequestsState];
      const index = tmpRemoveMyRequestsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveMyRequestsState.splice(index, 1);

      return tmpRemoveMyRequestsState;

    case types.CLEAN_STATE:
      return defaultState.myExitRequests;

    default:
      return myExitRequestsState;
  }
};

//Rehabilitations

const rehabilitations = (
  rehabilitationsState = defaultState.rehabilitations,
  action
) => {
  switch (action.type) {
    case types.SET_REHABILITATIONS:
      return action.data;

    case types.ADD_REHABILITATION: {
      const tmpRemoveReabilitationsState = [...rehabilitationsState];
      tmpRemoveReabilitationsState.unshift(action.data);
      return tmpRemoveReabilitationsState;
    }

    case types.UPDATE_REHABILITATION: {
      const tmpRemoveReabilitationsState = [...rehabilitationsState];
      const index = tmpRemoveReabilitationsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpRemoveReabilitationsState[index] = action.data;

      return tmpRemoveReabilitationsState;
    }

    case types.DELETE_REHABILITATION:
      const tmpRemoveReabilitationsState = [...rehabilitationsState];
      const index = tmpRemoveReabilitationsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveReabilitationsState.splice(index, 1);

      return tmpRemoveReabilitationsState;

    case types.CLEAN_STATE:
      return defaultState.rehabilitations;

    default:
      return rehabilitationsState;
  }
};

const exitTypes = (exitTypesState = defaultState.exitTypes, action) => {
  switch (action.type) {
    case types.SET_EXIT_TYPES: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.exitTypes;
    default:
      return exitTypesState;
  }
};
const employeeExitRequest = (
  employeeExitRequestState = defaultState.employeeExitRequest,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_EXIT_REQUEST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeExitRequest;

    default:
      return employeeExitRequestState;
  }
};
const exitRequestAttachments = (
  exitRequestAttachmentsState = defaultState.exitRequestAttachments,
  action
) => {
  switch (action.type) {
    case types.SET_EXIT_REQUEST_ATTACHMENTS: {
      return action.data;
    }

    case types.ADD_EXIT_REQUEST_ATTACHMENT: {
      const tmpState = [...exitRequestAttachmentsState];
      tmpState.unshift(action.data);
      return tmpState;
    }
    case types.UPDATE_EXIT_REQUEST_ATTACHMENTS: {
      const tmpState = [...exitRequestAttachmentsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      tmpState[index] = action.data;
      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.exitRequestAttachments;
    default:
      return exitRequestAttachmentsState;
  }
};
export default {
  myExitRequests,
  exitTypes,
  rehabilitations,
  employeeExitRequest,
  exitRequestAttachments
};
