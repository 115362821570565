import exitTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import download from "downloadjs";

const types = { ...commonTypes, ...exitTypes };

export const getMyExitRequests = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get("/api/exit/my-requests");

      dispatch({
        type: types.SET_MY_EXIT_REQUESTS,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getEmployeeExitRequest = (
  employeePositionId,
  setShowViewExitModal,
  handleCloseMenu
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/exit/employee-exit-requests/" + employeePositionId
      );

      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data,
      });

      setShowViewExitModal(true);
      handleCloseMenu();

      dispatch({ type: types.END_LOADING });
      //showSuccess("Exit requested submitted successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};
export const getRehabilitations = (setShowLoader) => {
  return async (dispatch) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get("/api/exit/rehabilitations");

      dispatch({
        type: types.SET_REHABILITATIONS,
        data,
      });
      setShowLoader(false);
    } catch (error) {
      setShowLoader(false);
      showError(error);
    }
  };
};

export const getExitTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/exit/exit-types");
      dispatch({
        type: types.SET_EXIT_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteMyExitRequest = (
  myRequest,
  setConfirmRemoveRequest,
  setSelectedRequest
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      console.log(myRequest);
      const { data } = await axios.delete(
        "/api/exit/my-requests/" + myRequest.id
      );
      dispatch({
        type: types.DELETE_MY_REQUEST,
        data: myRequest,
      });
      setConfirmRemoveRequest(false);
      setSelectedRequest(null);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveMyExitRequest = (
  formData,
  setFormData,
  setExitType,
  setAddRequestForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/exit/my-requests", formData);

      dispatch({ type: types.END_LOADING });

      showSuccess("Submitted successfully");

      dispatch({
        type: types.ADD_MY_REQUEST,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        exitTypeId: "",
        message: "",
        effectiveOn: "",
        attachedFiles: [],
      });

      setExitType(null);
      setAddRequestForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateMyExitRequest = (
  formData,
  setFormData,
  setExitType,
  setEditRequestForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/my-requests/" + formData.id,
        formData
      );

      dispatch({ type: types.END_LOADING });

      showSuccess("Submitted successfully");

      dispatch({
        type: types.UPDATE_MY_REQUEST,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        exitTypeId: "",
        message: "",
        effectiveOn: "",
      });

      setExitType(null);
      setEditRequestForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveRehabilitation = (
  formData,
  setFormData,
  setAddRequestForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/exit/rehabilitations", formData);

      dispatch({ type: types.END_LOADING });

      showSuccess("Submitted successfully");

      dispatch({
        type: types.ADD_REHABILITATION,
        data,
      });

      setFormData({
        blacklistId: formData.blacklistId,
        supportingDocument: "",
        message: "",
      });

      setAddRequestForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const updateRehabilitation = (
  formData,
  setFormData,
  setExitType,
  setEditRequestForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/rehabilitations/" + formData.id,
        formData
      );

      dispatch({ type: types.END_LOADING });

      showSuccess("Submitted successfully");

      dispatch({
        type: types.UPDATE_MY_REQUEST,
        data,
      });

      setFormData({
        employeePositionId: formData.employeePositionId,
        exitTypeId: "",
        message: "",
        effectiveOn: "",
      });

      setExitType(null);
      setEditRequestForm(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const deleteRehabilitation = (
  rehabilitation,
  setConfirmRemoveRequest,
  setSelectedRequest
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/exit/rehabilitations/" + rehabilitation.id
      );
      dispatch({
        type: types.DELETE_REHABILITATION,
        data: rehabilitation,
      });
      setConfirmRemoveRequest(false);
      setSelectedRequest(null);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const downloadExitLetter = (exitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/exit/download-exit-letter/" + exitId,
        {
          responseType: "blob",
        }
      );

      download(new Blob([data]), `Exit-letter - ${exitId}.pdf`, ".pdf");
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getExitRequestAttachments = (requestId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/exit/request-attachments/" + requestId
      );

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError(error);
    }
  };
};

export const updateExitRequestAttachment = (id, payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/exit/request-attachments/" + id,
        payload
      );
      showSuccess("Attachment updated successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const addExitRequestAttachment = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/exit/request-attachments",
        payload
      );
      showSuccess("Attachment saved successfully");
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_EXIT_REQUEST_ATTACHMENTS,
        data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const cancelEmployeeExitRequest = (request, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/exit/employee-exit-requests/" + request.id
      );

      dispatch({
        type: types.DELETE_MY_REQUEST,
        data: request,
      });
      dispatch({
        type: "UPDATE_EMPLOYEE",
        data: data,
      });
      dispatch({
        type: types.SET_EMPLOYEE_EXIT_REQUEST,
        data: null,
      });
      onClose();

      dispatch({ type: types.END_LOADING });
      showSuccess("Exit requested canceled successfully");
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};
